import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../services/api-service";
import { useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Main, ContenedorTitulo, ContenedorFechas } from "./ElementosStyles";
import "./ViewStatus.css"; // Importar el archivo CSS personalizado
import jsPDF from "jspdf"; // Importar jsPDF para generar el reporte en PDF
import "jspdf-autotable"; // Importar la extensión de autotable para jsPDF

const ViewStatus = () => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [data, setData] = useState([]);
    const [fechaGeneracion, setFechaGeneracion] = useState(null);
    const [sirenasFuncionales, setSirenasFuncionales] = useState(0);
    const [sirenasNoFuncionales, setSirenasNoFuncionales] = useState(0);

    const centeredButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    };

    const atras = () => {
        history.goBack();
    };

    const obtenerData = async () => {
        try {
            const Documento = {
                organizacion: organizacionn,
                localizacion: localizacionn,
            };
            const resultado = await Api.viweStatusSiren(Documento);
            setData(resultado);
            setFechaGeneracion(new Date().toLocaleString()); // Establecer la fecha de generación
            const funcionales = resultado.filter((item) => item.reportado).length;
            const noFuncionales = resultado.length - funcionales;
            setSirenasFuncionales(funcionales);
            setSirenasNoFuncionales(noFuncionales);
        } catch (error) {
            console.error("Error al obtener data:", error);
        }
    };

    const renderEstado = (estado) => {
        return estado ? "Reportó" : "No Funcionó";
    };

    const generarReportePDF = () => {
        const doc = new jsPDF();
        doc.text("Reporte de Estado de Sirenas", 20, 10);
        doc.text(`Fecha de Generación: ${fechaGeneracion}`, 20, 20);
        doc.text(`Sirenas Funcionales: ${sirenasFuncionales}`, 20, 30);
        doc.text(`Sirenas No Funcionales: ${sirenasNoFuncionales}`, 20, 40);

        const tableColumn = ["Nombre", "idSirena", "Estado"];
        const tableRows = data.map((item) => [
            item.nombre,
            item.idSirena,
            renderEstado(item.reportado),
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 50,
            styles: {
                cellWidth: 'wrap',
                fillColor: (rowIndex, node, column) => {
                    return node.raw[column.dataKey] === "No Funcionó" ? [255, 0, 0] : [255, 255, 255];
                },
                textColor: (rowIndex, node, column) => {
                    return node.raw[column.dataKey] === "No Funcionó" ? [255, 255, 255] : [0, 0, 0];
                },
            },
        });

        doc.save("reporte_sirenas.pdf");
    };

    return (
        <Main>
            <ContenedorFechas>
                <Button onClick={atras}>Atras</Button>
            </ContenedorFechas>
            <ContenedorTitulo>Verificar Estado de las Sirenas</ContenedorTitulo>

            <div style={centeredButtonStyle}>
                <Button className="buttonGeneral" onClick={obtenerData}>
                    Comenzar
                </Button>
            </div>

            {fechaGeneracion && (<div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={generarReportePDF}>Generar Reporte</Button>
                </div>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <strong>Fecha de Generación:</strong> {fechaGeneracion}
                </div>
            </div>

            )}

            {data.length > 0 && (
                <>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <strong>Sirenas Funcionales:</strong> {sirenasFuncionales}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <strong>Sirenas No Funcionales:</strong> {sirenasNoFuncionales}
                    </div>
                    <Table className="custom-table" style={{ marginTop: '20px' }}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>idSirena</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.nombre}</td>
                                    <td>{item.idSirena}</td>
                                    <td>{renderEstado(item.reportado)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </>
            )}
        </Main>
    );
};

export default ViewStatus;