import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  BotonDatos,
} from "./mapaElement";
import { ContenedorFechas } from "../Historico/ElementosStyles"
import { Button } from "react-bootstrap";
import InfoWindowEx from "./window"
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Mapa = (props) => {

  const [lat, setLat] = useState("")
  const [long, setLong] = useState("")
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [zoom, setZoom] = useState(12)
  const [probandooo, setProbando] = useState(false)

  let history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion")
  const latitudQuery = query.get("lat");
  const longitudQuery = query.get("long");


  useEffect(() => {
    if (latitudQuery && longitudQuery) {
      setLat(parseFloat(latitudQuery));
      setLong(parseFloat(longitudQuery));
      setZoom(18)
    } else {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          console.log(data.latitude)
          console.log(data.longitude)
          setLat(data.latitude);
          setLong(data.longitude)
        })
        .catch((error) => {
          console.error("Error al obtener la ubicación:", error);
        });
    }
  }, [location.search, latitudQuery, longitudQuery]);

  console.log("-----------------*--------------")
  console.log(selectedElement)


  function probando(e) {
    let valor
    if (localizacion === "sto_epconst_loca_2") {
      valor = e.idSirena
      valor = valor.replace("sto/", "");
    } else {
      valor = e.deviceID
    }
    let url = `/admin/viewDevicewt32?id=${valor}&organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    history.push(url);
  }

  const atras = () => {
    history.goBack();
  };

  return (
    <>
      {(latitudQuery && longitudQuery) ? (
        <>
          <ContenedorFechas>
            <Button onClick={(e) => atras()}>Atras</Button>
          </ContenedorFechas>
        </>
      ) : null}

      {(lat !== "" && long !== "") ? (
        <Map
          google={window.google}
          initialCenter={{
            lat: lat,
            lng: long,
          }}
          style={props.styleMapa}
          zoom={zoom}
        >
          {console.log("esta es la latitud y la longitud: ", lat , "y", long)}
          {props.devices.map((device) => {
            return (
              <Marker
                key={device.deviceID} // Añade una key prop a Marker
                id={device.deviceID}
                position={{
                  lat: device.ubicacion.coordinates[0],
                  lng: device.ubicacion.coordinates[1],
                }}
                onClick={(props, marker) => {
                  setSelectedElement(device);
                  setActiveMarker(marker);
                  setProbando(true);
                }}
              />
            );
          })}
          {selectedElement ? (
            <InfoWindowEx
              visible={showInfoWindow}
              marker={activeMarker}
              onCloseClick={() => {
                setSelectedElement(null);
              }}
            >
              <div>
                <p>{selectedElement.nombre}</p>
                <BotonDatos type="button" onClick={(e) => probando(selectedElement)}>
                  Ver datos
                </BotonDatos>
              </div>
            </InfoWindowEx>
          ) : null}
        </Map>
      ) : null}
    </>
  );

};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
