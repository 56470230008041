import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Card, Row, Col, Modal } from "react-bootstrap";
import { ContenedorTitulo, Main, ParaBoton, Title, Tarjeta } from "./AlcantarillaElements";
import { ContenedorFechas, TituloDispositivo } from "../Historico/ElementosStyles"
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Termometro from "../Historico/Widgets/Temperatura/TemperaturaM"
import Humedad from "../Historico/Widgets/MedidorHumedad/humedad"
import { FaDoorClosed, FaDoorOpen } from "react-icons/fa";
import { MdOutlineFlashOn, MdOutlineFlashOff } from "react-icons/md";
import { IoIosFlash, IoIosFlashOff } from "react-icons/io";
import LinePuerta from "../Historico/Widgets/LineChartEstados/index"
import LineChart from "../Historico/Widgets/LineChart/LineChart"
import LineChart2 from "../Historico/Widgets/LineChatTe/LineChart"
import LineEnergia from "../Historico/Widgets/LineChartEstados/energia"
import Tabla from "../Historico/Tabla/index"


let respuesta = {
	ultimosV: {
		_id: "654654654",
		ultimoDato: "13/12/2024 12:19",
		nombre: "test",
		temperature: 98.60,
		humidity: 54.60,
		puerta: true,
		energia: true,
	},
	valores: [
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 91.81,
			temperature: 7.46,
			puerta: true,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T09:29:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 91.05,
			temperature: 2.46,
			puerta: true,
			energia: false,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T10:30:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 15.81,
			temperature: 8.46,
			puerta: false,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T11:31:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 13.81,
			temperature: 4.46,
			puerta: true,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T12:32:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 91.81,
			temperature: 7.46,
			puerta: true,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T13:33:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 91.05,
			temperature: 2.46,
			puerta: true,
			energia: false,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T14:34:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 15.81,
			temperature: 8.46,
			puerta: false,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T15:35:00.000Z"
		},
		{
			_id: "660c16a604a08536d11fbd2a",
			humidity: 13.81,
			temperature: 4.46,
			puerta: true,
			energia: true,
			tipoSensor: "fijo",
			deviceID: "BC57290845DA",
			fechaCompleta: "2024-04-02T16:36:00.000Z"
		}
	]
}


const Index = () => {
	const query = new URLSearchParams(useLocation().search);
	const organizacionn = query.get("organizacion");
	const localizacionn = query.get("localizacion");
	const history = useHistory();
	const [intervalo, setIntervalo] = useState(10)
	const [fechaInicial, setFechaInicial] = useState(new Date().getTime() - 1000 * 60 * 60 * 24)
	const [fechaFinal, setFechaFinal] = useState(new Date().getTime())
	const [controladorC, setControladorC] = useState(false)
	const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
	let columnValues = [
		{ nColumna: 'Fecha', nValor: 'fechaCompleta' },
		{ nColumna: 'Temperatura', nValor: 'temperature' },
		{ nColumna: 'Humedad', nValor: 'humidity' },
		{ nColumna: 'Puerta', nValor: 'puerta' },
		{ nColumna: 'Energía', nValor: 'energia' },
	];

	const FechaUltima = () => {
		setControladorC(!controladorC)
	}

	const atras = () => {
		history.goBack();
	};


	return (
		<>
			<Main>
				<ParaBoton>
					<Button onClick={(e) => atras()}>Atras</Button>
				</ParaBoton>
				<Title>Monitoreo</Title>
				<ContenedorFechas>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="Fecha Inicial"
							value={fechaInicial}
							onChange={setFechaInicial}
						/>
					</LocalizationProvider>
				</ContenedorFechas>
				<ContenedorFechas>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							renderInput={(props) => <TextField {...props} />}
							label="Fecha Final"
							value={fechaFinal}
							onChange={setFechaFinal}
						/>
					</LocalizationProvider>
				</ContenedorFechas>
				<TextField
					label="Intervalo en minutos"
					value={intervalo}
					onChange={(e) => setIntervalo(e.target.value)}
					sx={{ width: 'fit-content' }}
				/>
				<ContenedorFechas>
					<Button className="buttonGeneral" onClick={FechaUltima} style={{
						margin: "0px 15px"
					}}>
						Buscar en Rango
					</Button>
				</ContenedorFechas>

				<Tarjeta>
					<TituloDispositivo>Últimos Valores</TituloDispositivo>
					{respuesta.ultimosV.ultimoDato}
					<div style={{ display: 'flex' }}>
						<div style={{ flex: 0.3 }}>
							Temperatura
							<Termometro info={respuesta.ultimosV} />
						</div>
						<div style={{ flex: 0.3 }}>
							Humedad
							<Humedad seriesData={respuesta.ultimosV} />
						</div>
						{respuesta.ultimosV.puerta === true ? (
							<div style={{ flex: 0.3 }}>
								Puerta abierta
								<FaDoorOpen style={{ width: '80%', height: '100%', color: 'rgba(111, 234, 208)' }} />
							</div>
						) : (
							<div style={{ flex: 0.3 }}>
								Puerta cerrada
								<FaDoorClosed style={{ width: '80%', height: '100%', color: 'rgba(111, 234, 208)' }} />
							</div>
						)}
						{respuesta.ultimosV.energia === true ? (
							<div style={{ flex: 0.3 }}>
								Con energía
								<IoIosFlash style={{ width: '80%', height: '100%', color: 'rgba(255, 208, 91)' }} />
							</div>
						) : (
							<div style={{ flex: 0.3 }}>
								Sin energía
								<IoIosFlashOff style={{ width: '80%', height: '100%', color: 'rgba(255, 208, 91)' }} />
							</div>
						)}
					</div>

				</Tarjeta>
				<Tarjeta>
					<TituloDispositivo>Diagramas</TituloDispositivo>
					<LineChart2 info={respuesta.valores} />
					<LineChart info={respuesta.valores} />
					<LinePuerta info={respuesta.valores} />
					<LineEnergia info={respuesta.valores} />
				</Tarjeta>
				<Tarjeta>
					<TituloDispositivo>Históricos</TituloDispositivo>
					<Tabla data={respuesta.valores} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} />
				</Tarjeta>
			</Main >
		</>
	)

}

export default Index