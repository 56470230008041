import React, { useEffect, useState } from "react";
import {
	ContenedorTitulo,
	Main,
	Titulo,
	ContenedorFecha
} from "../TestMapa/AlcantarillaElements";
import Api from "../../services/api-service"
import Tabla from "../Historico/Tabla/index"
import { useLocation } from "react-router-dom";


const lista = [
	{
		nombreDispositivo: "UPC 1",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	},
	{
		nombreDispositivo: "UPC 2",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	},
	{
		nombreDispositivo: "APC NetBotz 755",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	},
	{
		nombreDispositivo: "APC Maestro",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	},
	{
		nombreDispositivo: "APC Esclavo",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	},
	{
		nombreDispositivo: "Lovato DMG-610",
		ultimoDato: "2024-04-11T12:00:05.995Z"
	}
]

const Index = () => {
	const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
	const query = new URLSearchParams(useLocation().search);
	const obteOrganizacion = query.get("organizacion");
	const localizacion = query.get("localizacion");
	const [dispositivos, setDispositivos] = useState([])

	let columnValues = [
		{ nColumna: 'Nombre Dispositivo', nValor: 'nombre' },
		{ nColumna: 'ID Dispositivo', nValor: 'deviceID'},
		{ nColumna: 'Acciones', nValor: 'acciones' }
	]

	useEffect(async () => {
		try {
			console.log(obteOrganizacion, localizacion)
			Api.testingG(obteOrganizacion, localizacion).then(
				(respuesta) => {
					console.log(respuesta)
					setDispositivos(respuesta)
				}
			)
		} catch (error) {
			console.log(error, 'error');
		}
	}, []);
	return (
		<>
			<Main>
				<ContenedorTitulo>
					<Titulo>Lista de dispositivos</Titulo>
				</ContenedorTitulo>


				{dispositivos.length > 0 ? <Tabla data={dispositivos} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} /> : null}
			</Main >
		</>
	)
}

export default Index