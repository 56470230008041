import General from "./views/Usuario/General.js";
import CuentaUsuario from "./views/Usuario/CuentaUsuario.js";
import Home from "./views/Home.js";
import viewAllDashboard from './views/Dashboard/index'
import ubicacionSensores from "./views/Ubicacion Sensores/index.js"
import historico from "./views/Historico/index.js"
import eventos from "./views/Alertas/index.js"
import createAlert from "./views/Alertas/Create/index"
import crearCondicional from "./views/Alertas/Condicionales/index"
import crearMensajeC from "./views/Alertas/Mensanje"
import mediosAlertas from "./views/Alertas/Medios"
import viewOneALert from "./views/Alertas/viewLogs"
import viewDevice from './views/Dispositivos/index.js'
import cuentaUsuario from "./views/Usuario Cuentas/index"
import createUser from "./views/Usuario Cuentas/createUser/index"
import editUserOne from "./views/Usuario Cuentas/UserOne/index"
import procesosFav from "./views/Favorita/index.js"
import detalleFav from "./views/Favorita/DetalleProceso/index.js"
import mapaProceso from "./views/Favorita/Mapa/index.js"
import viewOne from "./views/Dispositivos/viewOne/viewOne.js"
import productosFav from "./views/Favorita/Productos/index.js"
import crearProductoFav from "./views/Favorita/Productos/crearProducto.js"
import detalleArea from "./views/Favorita/DetalleArea/index.js"
import mapa from "./views/TestMapa/index.js"
import devicewt32 from "./views/TestMapa/viewDevice.js"
import monitoreo from "./views/Monitoreo/index.js"
import monitoreoGeneral from "./views/Monitoreo/monitoreoGeneral.js"
import historicoSirena from "./views/TestMapa/historitco.js"
import listDispo from "./views/ListDispositivos/index.js"
import viewStatus from "./views/Sirenas/viewStatus.js"
import viewReports from "./views/Sirenas/viewReports.js"
import EditarAlerta from "./views/Alertas/EditarAlerta/index.js"

const dashboardRoutes = [
  {
    path: "/home",
    component: Home,
    layout: "/login",
  },
  {
    path: "/general",
    component: General,
    layout: "/admin",
  },
  {
    path: "/cuentaUsuario",
    component: CuentaUsuario,
    layout: "/admin",
  },
  {
    path: "/viewAllDashboard",
    component: viewAllDashboard,
    layout: "/admin",
  },
  {
    path: "/ubicacionSensores",
    component: ubicacionSensores,
    layout: "/admin",
  },
  {
    path: "/events",
    component: eventos,
    layout: "/admin",
  },
  {
    path: "/historico",
    component: historico,
    layout: "/admin",
  },
  {
    path: "/createAlert",
    component: createAlert,
    layout: "/admin",
  },
  {
    path: "/createCondition",
    component: crearCondicional,
    layout: "/admin",
  },
  {
    path: "/messageAlert",
    component: crearMensajeC,
    layout: "/admin",
  },
  {
    path: "/mediaAlert",
    component: mediosAlertas,
    layout: "/admin",
  },
  {
    path: "/viewAlert",
    component: viewOneALert,
    layout: "/admin",
  },
  {
    path: "/dispositivos",
    component: viewDevice,
    layout: "/admin",
  },
  {
    path: "/userCount",
    component: cuentaUsuario,
    layout: "/admin",
  },
  {
    path: "/createUser",
    component: createUser,
    layout: "/admin",
  },
  {
    path: "/editUserOne",
    component: editUserOne,
    layout: "/admin",
  },

  //JGVS
  {
    path: "/procesos",
    component: procesosFav,
    layout: "/admin",
  },
  {
    path: "/detalleProceso",
    component: detalleFav,
    layout: "/admin",
  },
  {
    path: "/mapaProceso",
    component: mapaProceso,
    layout: "/admin",
  },
  {
    path: "/viewOneDevice",
    component: viewOne,
    layout: "/admin",
  },
  {
    path: "/verProductos",
    component: productosFav,
    layout: "/admin",
  },
  {
    path: "/crearProducto",
    component: crearProductoFav,
    layout: "/admin"
  },
  {
    path: "/area",
    component: detalleArea,
    layout: "/admin"
  },
  {
    path: '/mapa',
    component: mapa,
    layout: "/admin"
  },
  {
    path: '/viewDevicewt32',
    component: devicewt32,
    layout: "/admin"
  },
  {
    path: '/monitoreo',
    component: monitoreo,
    layout: "/admin"
  },
  {
    path: '/historicoSirena',
    component: historicoSirena,
    layout: "/admin"
  },
  {
    path: '/monitoreoGeneral',
    component: monitoreoGeneral,
    layout: "/admin"
  },
  {
    path: '/listaDispositivos',
    component: listDispo,
    layout: "/admin"
  },
  {
    path: '/viewStatus',
    component: viewStatus,
    layout: "/admin"
  },
  {
    path: '/viewReports',
    component: viewReports,
    layout: "/admin"
  },
  {
    path: '/editAlert',
    component: EditarAlerta,
    layout: "/admin"
  }
  //JGVS
];

export default dashboardRoutes;
